body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
/* body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

/*  css */
/* css button ajouter une note */
#affixNote {
  top: 80px;
  position: relative;
  left: 1230px;
  width: 222px;
  z-index: 1000;
  border-radius: 15px;
  margin:7px;
  /* margin-left: -380px; */
  /* margin-left: 584px */
}

textarea#ficheCmpagnieText {
  width: 100%;
  padding-top: 13px;
  padding-inline: 17px;
  padding-bottom: -45px;
  height: 120px;
  border: none;
  font-family: 'FontAwesome';
  font-size: larger;
}
.modal-body {
font-family: 'FontAwesome';
font-size: larger;
color: black;
}
.modalHeader{
  color: #20a8d8;
}
.modalficheCompagnie{
display: flex;

}

/* .logopdf2 {
  border-left: 1px solid gray;
} */

.textFicheCompagnie{
  flex: 5;
  padding-right: 20px;
}

.imgCompagnie{
    /* margin-left: 9px; */
    display: flex;
    align-content: center;
}
.LogoPdfImg {
  height: auto;
  border-left: solid 1px gray;
  display: grid;
  justify-content: start;
  justify-items: start;
  padding-left: 11px;
}
/* .LogoImg {
  height: auto;
  border-left: solid 1px gray;
  display: grid;
  justify-content: start;
  justify-items: start;
  padding-left: 11px;

} */

textarea.updateFicheText {
  width: 100%;
  height: 309px;
  border: none;
}
/* //  correction modal css */
.modalBodyFicheCompagnie{
  /* height: 330px; */
  height: auto;
}


span.Televerser {
  color: #20a8d8;
  cursor: pointer;
  margin-left: 17px;
}
.Telecharger{
  width: 100px;
  height: 36px ;
  text-align: center;
  display: flex ;
  justify-content: center ;
  flex-direction: column ;
  font-family: sans-serif;
  margin-left: 5px;
  color: #20a8d8 ;
  font-size: 13px;
}
.nameFileupload{
  width: 100px;
  height: 36px ;
  text-align: center;
  display: flex ;
  justify-content: center ;
  flex-direction: column ;
  font-family: sans-serif;
    font-size: 13px;
    color: #20a8d8 ;
}
/* .telechargement{
  font-size: 16px !important;
} */
.parrainage{
  color: gray;
}

.iconFicheName{
  display: flex;
  justify-content: space-between;
}
.pdfImage{
flex:1;
}
.logopdf.logopdf2 {
  border-left: 1px gray solid;
}
  

/* ///  css script APPEL */

.labelUpdate {
  display: flex;
  justify-content: flex-start
 
}
.listingApproche {
  margin-left: 80px;
}

.listingSecteurActivite {
  display: flex;
  margin-left: 35px;
}
label.labelContenu {
  display: flex;
 
}

.ant-col.ant-col-24 {
  flex-wrap: wrap !important;
}
/*  erreur police CRM */
td {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
th {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* // Desactiver copier coller via css  */

 .componentAffectationContact:nth-of-type(1){
   user-select:none;
 }

 /* styles.css (ou tout autre nom de fichier CSS que vous utilisez dans votre projet) */

.calendar-container {
  width: 100%;
  max-width: 800px; /* Ajustez selon vos besoins */
  margin: 0 auto; /* Centre l'iFrame horizontalement */
  padding: 20px; /* Espacement autour du calendrier */
  border: 1px solid #ccc; /* Bordure grise */
  border-radius: 5px; /* Coins arrondis */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.calendar-container iframe {
  width: 100%;
  height: 600px; /* Hauteur du calendrier */
  border: none; /* Pas de bordure sur l'iFrame */
}

#SearchIconTvDisplay4{
  color:black;
  margin-Right:15px;
  cursor:pointer;
}
.tablesTvDisplay4{
  display: flex;
  align-items: center;
 justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
}
.input-placeholder:focus{
  border: none !important;
}

.ant-tabs-tab {
  height: 35px;
}

.custom-tooltip {
  background-color: #5585b5 !important;
  color: white !important;
  font-size: 14px !important;
 
  
}

.custom-arrow {
  color: #5585b5 !important;
}
